var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var lfa,mfa,nfa,ofa,pfa,qfa,rfa,OG,sfa,tfa,ufa,vfa,wfa,xfa,yfa,zfa,QG,Afa;$CLJS.JG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);lfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.KG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);mfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
nfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.LG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.MG=new $CLJS.M(null,"column-name","column-name",551523580);ofa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.NG=new $CLJS.M(null,"display-info","display-info",-816930907);pfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
qfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);rfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);OG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);sfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);tfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);ufa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
vfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);wfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);xfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);yfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.PG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);zfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
QG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.RG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);Afa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.zF($CLJS.Qx,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null)],null)],null)],null)]));$CLJS.zF($CLJS.jG,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null)],null)],null)],null)]));
$CLJS.xF($CLJS.cG,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.xF($CLJS.OF,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null)],null)]));$CLJS.xF($CLJS.rG,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null)]));
$CLJS.xF($CLJS.hk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)]));$CLJS.qF($CLJS.hk,$CLJS.cF);$CLJS.xF($CLJS.wG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.qF($CLJS.wG,$CLJS.cF);$CLJS.xF($CLJS.Xn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)]));$CLJS.qF($CLJS.Xn,$CLJS.cF);
$CLJS.X(OG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.pt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.pt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.xF($CLJS.UF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OG],null)]));$CLJS.qF($CLJS.UF,$CLJS.cF);
$CLJS.xF($CLJS.pG,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null)]));$CLJS.xF($CLJS.xG,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.xF($CLJS.vG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));
$CLJS.xF($CLJS.EG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.qF($CLJS.vG,$CLJS.cF);$CLJS.qF($CLJS.EG,$CLJS.cF);$CLJS.xF($CLJS.CG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null)]));$CLJS.qF($CLJS.CG,$CLJS.cF);
$CLJS.xF($CLJS.PF,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.X(QG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ps,zfa,xfa,qfa,mfa,lfa,pfa,rfa,sfa,tfa,wfa,ufa,Afa,yfa,nfa,vfa,$CLJS.Bl],null));$CLJS.X($CLJS.KG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Xn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QG],null)],null));
$CLJS.SG=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.bG,$CLJS.Qx,$CLJS.LG,!1,$CLJS.PG,$CLJS.DF,$CLJS.NG,function(){return new $CLJS.h(null,3,[$CLJS.nE,$CLJS.KE("Count of rows"),$CLJS.MG,$CLJS.KE("Count"),$CLJS.Jt,$CLJS.KE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bG,$CLJS.vG,$CLJS.JG,$CLJS.dk,$CLJS.LG,!0,$CLJS.PG,$CLJS.DF,$CLJS.NG,function(){return new $CLJS.h(null,3,[$CLJS.nE,$CLJS.KE("Sum of ..."),$CLJS.MG,$CLJS.KE("Sum"),$CLJS.Jt,$CLJS.KE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.bG,$CLJS.cG,$CLJS.JG,$CLJS.dk,$CLJS.LG,!0,$CLJS.PG,$CLJS.DF,$CLJS.NG,function(){return new $CLJS.h(null,3,[$CLJS.nE,$CLJS.KE("Average of ..."),$CLJS.MG,$CLJS.KE("Average"),$CLJS.Jt,$CLJS.KE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.bG,$CLJS.wG,$CLJS.JG,$CLJS.dk,$CLJS.LG,!0,$CLJS.PG,$CLJS.eG,$CLJS.NG,function(){return new $CLJS.h(null,3,[$CLJS.nE,$CLJS.KE("Median of ..."),$CLJS.MG,$CLJS.KE("Median"),$CLJS.Jt,$CLJS.KE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.bG,$CLJS.OF,$CLJS.JG,$CLJS.Gs,$CLJS.LG,!0,$CLJS.PG,$CLJS.DF,$CLJS.NG,function(){return new $CLJS.h(null,3,[$CLJS.nE,$CLJS.KE("Number of distinct values of ..."),$CLJS.MG,$CLJS.KE("Distinct values"),$CLJS.Jt,$CLJS.KE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bG,$CLJS.EG,$CLJS.JG,$CLJS.dk,$CLJS.LG,!0,$CLJS.PG,$CLJS.DF,$CLJS.NG,function(){return new $CLJS.h(null,3,[$CLJS.nE,$CLJS.KE("Cumulative sum of ..."),
$CLJS.MG,$CLJS.KE("Sum"),$CLJS.Jt,$CLJS.KE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.bG,$CLJS.jG,$CLJS.LG,!1,$CLJS.PG,$CLJS.DF,$CLJS.NG,function(){return new $CLJS.h(null,3,[$CLJS.nE,$CLJS.KE("Cumulative count of rows"),$CLJS.MG,$CLJS.KE("Count"),$CLJS.Jt,$CLJS.KE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bG,$CLJS.xG,$CLJS.JG,$CLJS.dk,$CLJS.LG,
!0,$CLJS.PG,$CLJS.sG,$CLJS.NG,function(){return new $CLJS.h(null,3,[$CLJS.nE,$CLJS.KE("Standard deviation of ..."),$CLJS.MG,$CLJS.KE("SD"),$CLJS.Jt,$CLJS.KE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bG,$CLJS.Xn,$CLJS.JG,$CLJS.Xi,$CLJS.LG,!0,$CLJS.PG,$CLJS.DF,$CLJS.NG,function(){return new $CLJS.h(null,3,[$CLJS.nE,$CLJS.KE("Minimum of ..."),$CLJS.MG,$CLJS.KE("Min"),$CLJS.Jt,$CLJS.KE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.bG,$CLJS.hk,$CLJS.JG,$CLJS.Xi,$CLJS.LG,!0,$CLJS.PG,$CLJS.DF,$CLJS.NG,function(){return new $CLJS.h(null,3,[$CLJS.nE,$CLJS.KE("Maximum of ..."),$CLJS.MG,$CLJS.KE("Max"),$CLJS.Jt,$CLJS.KE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ofa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.RG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ms],null),$CLJS.hf.h($CLJS.bG),$CLJS.SG)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JG,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.Wi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LG,$CLJS.Fs],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.PG,$CLJS.Wi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NG,$CLJS.md],null)],null));