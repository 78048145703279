var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Ara,Bra,z7,Cra,Dra,Era,Fra,Gra,Hra,Ira,A7,B7,C7,Jra,D7;
Ara=function(a){return function(b){var c=$CLJS.$e($CLJS.bh);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Id($CLJS.q(c),t))return l;c.Dd(null,$CLJS.be.g(c.Qb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};Bra=function(a,b,c){return $CLJS.eg.j($CLJS.Cf,$CLJS.hE(function(d){return $CLJS.J0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.t0,c,$CLJS.n0,!1],null))}),$CLJS.EN.h($CLJS.FW(a,b)))};z7=function(a,b,c){return $CLJS.eg.j($CLJS.Cf,$CLJS.hE(function(d){return $CLJS.H0.v(a,b,d,c)}),$CLJS.EN.h($CLJS.FW(a,b)))};$CLJS.Q3=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.QW.l(c,d,$CLJS.R,$CLJS.H([$CLJS.q7,$CLJS.H0.j(c,d,$CLJS.FW(c,d))]))},a,$CLJS.su(0,$CLJS.EW(a,b)))};
Cra=function(a,b){a=$CLJS.FW(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Xt),d=$CLJS.J.g(a,$CLJS.OV);b=$CLJS.q7.h(a);if($CLJS.n(b))return b;var e=$CLJS.oV.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.bW);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.T:null;switch(k){case "mbql.stage/native":return $CLJS.SZ;case "mbql.stage/mbql":return $CLJS.MZ;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Fe(function(){return function m(l){return new $CLJS.ne(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z);C=$CLJS.el.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.wZ,$CLJS.T.h(C),$CLJS.JZ,$CLJS.T.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.PK,f],null)]));x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);return $CLJS.ee($CLJS.el.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.wZ,$CLJS.T.h(x),$CLJS.JZ,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.PK,f],null)])),m($CLJS.Lc(t)))}return null}},null,null)}($CLJS.CW.h(e))}())}return null};
Dra=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),z=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.yA($CLJS.wZ,$CLJS.T)(x);x=$CLJS.D1(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.PK,$CLJS.dL,$CLJS.H([$CLJS.wZ,S,$CLJS.JZ,x]));z.add(C);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k);return $CLJS.ee($CLJS.R.l(u,$CLJS.PK,$CLJS.dL,$CLJS.H([$CLJS.wZ,$CLJS.yA($CLJS.wZ,$CLJS.T)(u),$CLJS.JZ,function(){var v=$CLJS.D1(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.V5.g(a,b))}())};
Era=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),z=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.PK,$CLJS.jL,$CLJS.H([$CLJS.wZ,S,$CLJS.JZ,x]));z.add(C);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}var u=
$CLJS.A(k);return $CLJS.ee($CLJS.R.l(u,$CLJS.PK,$CLJS.jL,$CLJS.H([$CLJS.wZ,$CLJS.T.h(u),$CLJS.JZ,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.H4.g(a,b))}())};
Fra=function(a,b,c){var d=$CLJS.FW(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.SE);return $CLJS.Fe(function(){return function l(k){return new $CLJS.ne(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);return function(){for(var K=0;;)if(K<u){var S=$CLJS.kd(t,K),V=$CLJS.I(S,0,null),Z=function(){var ra=V;ra=ra instanceof $CLJS.M?ra.T:null;switch(ra){case "field":return $CLJS.RZ;case "expression":return $CLJS.DZ;default:throw Error(["No matching clause: ",
$CLJS.p.h(ra)].join(""));}}(),ha=$CLJS.Y_.j(a,b,S);$CLJS.ue(v,$CLJS.R.l(ha,$CLJS.PK,Z,$CLJS.H([$CLJS.wZ,$CLJS.A0.j(a,b,ha),$CLJS.JZ,function(){var ra=$CLJS.D1(a,ha);return c.h?c.h(ra):c.call(null,ra)}()])));K+=1}else return!0}()?$CLJS.te($CLJS.ve(v),l($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}var x=$CLJS.A(m),z=$CLJS.I(x,0,null),C=function(){var K=z;K=K instanceof $CLJS.M?K.T:null;switch(K){case "field":return $CLJS.RZ;case "expression":return $CLJS.DZ;default:throw Error(["No matching clause: ",$CLJS.p.h(K)].join(""));
}}(),G=$CLJS.Y_.j(a,b,x);return $CLJS.ee($CLJS.R.l(G,$CLJS.PK,C,$CLJS.H([$CLJS.wZ,$CLJS.A0.j(a,b,G),$CLJS.JZ,function(){var K=$CLJS.D1(a,G);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Lc(m)))}return null}},null,null)}(e)}())}return null};Gra=function(a,b,c){return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.hE(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[Dra,Era],null)))};
Hra=function(a,b,c){var d=$CLJS.Z1(a,b);return $CLJS.n(d)?$CLJS.Fe(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);return function(){for(var z=0;;)if(z<t){var C=$CLJS.kd(m,z);var G=$CLJS.yA($CLJS.JZ,$CLJS.wZ)(C);G=$CLJS.n(G)?G:$CLJS.A0.j(a,b,C);C=$CLJS.Fk.l($CLJS.el.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.PK,$CLJS.xZ,$CLJS.wZ,G,$CLJS.JZ,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.I1.h(C))?
$CLJS.cm(C,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.I1],null)):null])),$CLJS.JW,$CLJS.H([$CLJS.rE]));u.add(C);z+=1}else return!0}()?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}var v=$CLJS.A(l),x=function(){var z=$CLJS.yA($CLJS.JZ,$CLJS.wZ)(v);return $CLJS.n(z)?z:$CLJS.A0.j(a,b,v)}();return $CLJS.ee($CLJS.Fk.l($CLJS.el.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.PK,$CLJS.xZ,$CLJS.wZ,x,$CLJS.JZ,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.I1.h(v))?$CLJS.cm(v,new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.I1],null)):null])),$CLJS.JW,$CLJS.H([$CLJS.rE])),k($CLJS.Lc(l)))}return null}},null,null)}($CLJS.H0.j(a,d,$CLJS.FW(a,d)))}()):null};Ira=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.V_(a,c),$CLJS.n(c)?$CLJS.Fe($CLJS.J0.v(a,b,c,d)):null):null};
A7=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),z=t;var C=$CLJS.pB.h(x);var G=$CLJS.R.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.X1(G.call($CLJS.R,K,$CLJS.PK,$CLJS.DZ,$CLJS.H([$CLJS.wZ,S,$CLJS.JZ,x])),$CLJS.Ei,$CLJS.n(C)?C:$CLJS.zj);z.add(C);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),
f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}var u=$CLJS.A(k);return $CLJS.ee(function(){var v=$CLJS.pB.h(u),x=$CLJS.R.l,z=u,C=$CLJS.T.h(u);var G=$CLJS.T.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.X1(x.call($CLJS.R,z,$CLJS.PK,$CLJS.DZ,$CLJS.H([$CLJS.wZ,C,$CLJS.JZ,G])),$CLJS.Ei,$CLJS.n(v)?v:$CLJS.zj)}(),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.Wna.g(a,b))}())};
B7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.t0);return $CLJS.Pk.g(function(f){return $CLJS.Fk.l(f,$CLJS.XZ,$CLJS.H([$CLJS.JW,$CLJS.PW,$CLJS.aL]))},function(){var f=Hra(a,b,e);if($CLJS.n(f))return f;f=$CLJS.FW(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.fP),l=$CLJS.J.g(f,$CLJS.OV),m=$CLJS.n(k)?function(){var t=$CLJS.T_(a,k);return $CLJS.J0.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?Ira(a,b,l,$CLJS.R.j(d,$CLJS.n0,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.ne(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.Ad(x)){var z=$CLJS.lc(x),C=$CLJS.D(z),G=$CLJS.qe(C);return function(){for(var S=0;;)if(S<C){var V=$CLJS.kd(z,S),Z=G,ha=$CLJS.R,ra=ha.l,Na=V,zb=$CLJS.T.h(V);V=$CLJS.T.h(V);V=e.h?e.h(V):e.call(null,V);ha=ra.call(ha,Na,$CLJS.PK,$CLJS.SZ,$CLJS.H([$CLJS.wZ,zb,$CLJS.JZ,V]));Z.add(ha);S+=1}else return!0}()?$CLJS.te($CLJS.ve(G),v($CLJS.mc(x))):$CLJS.te($CLJS.ve(G),null)}var K=$CLJS.A(x);return $CLJS.ee($CLJS.R.l(K,$CLJS.PK,$CLJS.SZ,$CLJS.H([$CLJS.wZ,$CLJS.T.h(K),
$CLJS.JZ,function(){var S=$CLJS.T.h(K);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Lc(x)))}return null}},null,null)}($CLJS.CW.h($CLJS.oV.h(f)))}())};
C7=function(a,b){var c=$CLJS.I(a,0,null);$CLJS.I(a,1,null);var d=$CLJS.I(a,2,null);switch(c instanceof $CLJS.M?c.T:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.E.g(d,$CLJS.Yi.h(b));throw $CLJS.hi("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.aS,a,$CLJS.Si,b],null));case "expression":return $CLJS.E.g(d,$CLJS.T.h(b));default:throw $CLJS.hi("unknown clause in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.aS,a,$CLJS.Si,b],null));}};
Jra=function(a,b,c){var d=$CLJS.XF.h($CLJS.FW(a,b));return $CLJS.n(d)?function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){var m=l;if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);return function(){for(var z=0;;)if(z<u){var C=$CLJS.kd(t,z);$CLJS.ue(v,function(){var G=$CLJS.uH(function(V,Z){return function(ha){return C7(ha,Z)}}(z,C,t,u,v,m,l,d,d),d);if($CLJS.n(G)){var K=$CLJS.m7(G);G=$CLJS.E2(G);G=$CLJS.O(G);G=$CLJS.J.g(G,$CLJS.ri);var S=C;K=$CLJS.n(K)?$CLJS.u5(S,
K):S;return $CLJS.n(G)?$CLJS.w3(K,G):K}return C}());z+=1}else return!0}()?$CLJS.te($CLJS.ve(v),k($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}var x=$CLJS.A(m);return $CLJS.ee(function(){var z=$CLJS.uH(function(K){return function(S){return C7(S,K)}}(x,m,l,d,d),d);if($CLJS.n(z)){var C=$CLJS.m7(z);z=$CLJS.E2(z);z=$CLJS.O(z);z=$CLJS.J.g(z,$CLJS.ri);var G=x;C=$CLJS.n(C)?$CLJS.u5(G,C):G;return $CLJS.n(z)?$CLJS.w3(C,z):C}return x}(),k($CLJS.Lc(m)))}return null}},null,null)}(c):c};
D7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.Apa={};$CLJS.qF($CLJS.VV,D7);$CLJS.qF($CLJS.bW,D7);$CLJS.o1.m(null,$CLJS.VV,function(a){return $CLJS.n1(a,new $CLJS.h(null,2,[$CLJS.YF,$CLJS.Ve($CLJS.Pk,$CLJS.o1),$CLJS.iW,$CLJS.Ve($CLJS.Pk,$CLJS.o1)],null))});$CLJS.E0.m(null,D7,function(){throw $CLJS.hi("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.I0.m(null,D7,function(a,b,c,d){d=$CLJS.O(d);c=$CLJS.J.g(d,$CLJS.t0);var e=$CLJS.J.g(d,$CLJS.n0);a=$CLJS.Q3(a,b);var f=$CLJS.O(d);var k=$CLJS.J.g(f,$CLJS.t0),l=$CLJS.J.g(f,$CLJS.r0),m=$CLJS.J.g(f,$CLJS.x0);f=$CLJS.gf.l(B7(a,b,f),$CLJS.n(m)?A7(a,b,k):null,$CLJS.H([$CLJS.n(l)?Bra(a,b,k):null]));k=$CLJS.gf.g;d=$CLJS.n(e)?(e=$CLJS.Va($CLJS.OV.h($CLJS.FW(a,b))))?e:$CLJS.v0.h(d):e;return Jra(a,b,k.call($CLJS.gf,f,$CLJS.n(d)?$CLJS.E1(a,b,f,c):null))});
$CLJS.G0.m(null,D7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.t0);var e=Cra(a,b);if($CLJS.n(e))return e;a=$CLJS.Q3(a,b);e=Gra(a,b,d);var f=Fra(a,b,d);return $CLJS.n(e)?$CLJS.eg.g(e,f):$CLJS.n(f)?($CLJS.mh(f),$CLJS.eg.j($CLJS.Cf,Ara(function(k){return $CLJS.Fk.l(k,$CLJS.VZ,$CLJS.H([$CLJS.PK,$CLJS.b1,$CLJS.JZ]))}),$CLJS.gf.g(f,z7(a,b,c)))):$CLJS.gf.l(B7(a,b,new $CLJS.h(null,2,[$CLJS.n0,!1,$CLJS.t0,d],null)),A7(a,b,d),$CLJS.H([z7(a,b,c)]))});$CLJS.y0.m(null,$CLJS.bW,function(){return $CLJS.KE("Native query")});
var E7=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fP,$CLJS.OV,$CLJS.EN],null),F7=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.YF,$CLJS.XF,$CLJS.iW,$CLJS.PR,$CLJS.mS],null);
$CLJS.y0.m(null,$CLJS.VV,function(a,b,c,d){var e=$CLJS.Q3(a,b);a=$CLJS.Fe(function(){var f=$CLJS.eg.j($CLJS.N,$CLJS.Nk.g($CLJS.qC,$CLJS.hf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.tna.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[E7,F7],null)),k=$CLJS.At(" + ",$CLJS.Ok.g($CLJS.DA,$CLJS.hf.g(f,E7)));f=$CLJS.hf.g(f,F7);return $CLJS.At(", ",$CLJS.Ok.g($CLJS.DA,$CLJS.ee(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.Z1(e,b);return $CLJS.n(a)?$CLJS.Z_.v(e,a,$CLJS.FW(e,a),d):null});