var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var BL,DL,FL,IL,NL;$CLJS.yL=function(a){return $CLJS.Wh($CLJS.q($CLJS.fC),a,$CLJS.mG)};$CLJS.zL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.AL=new $CLJS.M(null,"object-id","object-id",-754527291);BL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.CL=new $CLJS.M(null,"operators","operators",-2064102509);
DL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.EL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);FL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.GL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.HL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
IL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.JL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.KL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.LL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.ML=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
NL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.OL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(DL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.xE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.dH],null)],null)],null));$CLJS.X(NL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,DL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.BE],null)],null)],null));$CLJS.X(FL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.iG],null),NL,$CLJS.sE],null));
$CLJS.X(IL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.iG],null),DL,$CLJS.hL],null));
$CLJS.IG.g($CLJS.iG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.iG],null),DL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.hL,$CLJS.sE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,2,[$CLJS.Ni,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.dC(a)):null},$CLJS.pt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.hB,IL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gB,FL],null)],null)],null));$CLJS.qF($CLJS.iG,$CLJS.mG);$CLJS.ME.m(null,$CLJS.iG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Ei,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.ZE});$CLJS.xF($CLJS.mB,$CLJS.H([$CLJS.sE]));$CLJS.ME.m(null,$CLJS.mB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Ei,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.ZE});
$CLJS.qF($CLJS.mB,$CLJS.mG);$CLJS.X(BL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.xE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.sE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.sE],null)],null)],null));$CLJS.IG.g($CLJS.YF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.YF],null),BL,$CLJS.Xj],null));
$CLJS.ME.m(null,$CLJS.YF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Ei,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.ZE});$CLJS.qF($CLJS.YF,$CLJS.mG);$CLJS.xF($CLJS.HF,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QK],null)],null)]));$CLJS.qF($CLJS.HF,$CLJS.mG);
$CLJS.xF($CLJS.dG,$CLJS.H([$CLJS.it,$CLJS.ZE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OK],null)],null)]));$CLJS.qF($CLJS.dG,$CLJS.mG);
$CLJS.X($CLJS.mG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.OE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.rt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.At(", ",$CLJS.UG($CLJS.Jd,$CLJS.il.g($CLJS.q($CLJS.fC),$CLJS.mG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.yL(a)}],null)],null));