var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var d4,f4,g4,h4,i4,j4,Tpa;d4=function(a,b,c){var d=$CLJS.FW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.YF);d=$CLJS.uH($CLJS.Nk.j($CLJS.ch([c]),$CLJS.zE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.hi($CLJS.iE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.Es,c,$CLJS.FF,a,$CLJS.EL,b],null));return d};$CLJS.e4=function(a){return $CLJS.Y1($CLJS.vG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
f4=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);g4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);h4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);i4=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);
j4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Tpa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.B0.m(null,$CLJS.YF,function(a,b){var c=$CLJS.Fe($CLJS.YF.h($CLJS.FW(a,b)));return $CLJS.n(c)?$CLJS.$1($CLJS.KE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.Z_.v(a,b,v,$CLJS.$_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.Z_.v(a,
b,t,$CLJS.$_),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.E0.m(null,$CLJS.YF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.pB);e=$CLJS.J.g(e,$CLJS.Ei);c=$CLJS.I(c,2,null);c=d4(a,b,c);return $CLJS.el.l($CLJS.H([$CLJS.Y_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.PK,$CLJS.jL,$CLJS.b1,$CLJS.zE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.pB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ei,e],null):null]))});
$CLJS.y0.m(null,$CLJS.YF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.Z_.v(a,b,d4(a,b,c),d)});$CLJS.qF(h4,j4);
for(var k4=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qx,$CLJS.jG,$CLJS.rG],null)),l4=null,m4=0,n4=0;;)if(n4<m4){var Upa=l4.X(null,n4);$CLJS.qF(Upa,h4);n4+=1}else{var o4=$CLJS.y(k4);if(o4){var p4=o4;if($CLJS.Ad(p4)){var q4=$CLJS.lc(p4),Vpa=$CLJS.mc(p4),Wpa=q4,Xpa=$CLJS.D(q4);k4=Vpa;l4=Wpa;m4=Xpa}else{var Ypa=$CLJS.A(p4);$CLJS.qF(Ypa,h4);k4=$CLJS.B(p4);l4=null;m4=0}n4=0}else break}
$CLJS.y0.m(null,h4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.Z_.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.iE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.iE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.KE("Count");case "cum-count":return $CLJS.KE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.z0.m(null,h4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.E0.m(null,i4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.nI($CLJS.E0,j4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Tj,$CLJS.TC)});
$CLJS.qF(i4,j4);$CLJS.qF(h4,i4);$CLJS.qF($CLJS.OF,i4);$CLJS.y0.m(null,$CLJS.SF,function(){return $CLJS.KE("Case")});$CLJS.z0.m(null,$CLJS.SF,function(){return"case"});$CLJS.qF(g4,j4);
for(var r4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.cG,$CLJS.EG,$CLJS.OF,$CLJS.hk,$CLJS.wG,$CLJS.Xn,$CLJS.xG,$CLJS.vG,$CLJS.PF],null)),s4=null,t4=0,u4=0;;)if(u4<t4){var Zpa=s4.X(null,u4);$CLJS.qF(Zpa,g4);u4+=1}else{var v4=$CLJS.y(r4);if(v4){var w4=v4;if($CLJS.Ad(w4)){var x4=$CLJS.lc(w4),$pa=$CLJS.mc(w4),aqa=x4,bqa=$CLJS.D(x4);r4=$pa;s4=aqa;t4=bqa}else{var cqa=$CLJS.A(w4);$CLJS.qF(cqa,g4);r4=$CLJS.B(w4);s4=null;t4=0}u4=0}else break}
$CLJS.z0.m(null,g4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.y0.m(null,g4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.Z_.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.iE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.iE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.iE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.iE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.iE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.iE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.iE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.iE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.iE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.y0.m(null,$CLJS.UF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.iE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.Z_.v(a,b,e,d)]))});$CLJS.z0.m(null,$CLJS.UF,function(){return"percentile"});
$CLJS.qF(f4,j4);for(var y4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UF,$CLJS.PF],null)),z4=null,A4=0,B4=0;;)if(B4<A4){var dqa=z4.X(null,B4);$CLJS.qF(dqa,f4);B4+=1}else{var C4=$CLJS.y(y4);if(C4){var D4=C4;if($CLJS.Ad(D4)){var E4=$CLJS.lc(D4),eqa=$CLJS.mc(D4),fqa=E4,gqa=$CLJS.D(E4);y4=eqa;z4=fqa;A4=gqa}else{var hqa=$CLJS.A(D4);$CLJS.qF(hqa,f4);y4=$CLJS.B(D4);z4=null;A4=0}B4=0}else break}
$CLJS.E0.m(null,f4,function(a,b,c){var d=$CLJS.Fk.g,e=$CLJS.nI($CLJS.E0,j4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Fk,a,$CLJS.Tj)});$CLJS.y0.m(null,$CLJS.CG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.iE("Sum of {0} matching condition",$CLJS.H([$CLJS.Z_.v(a,b,e,d)]))});
$CLJS.z0.m(null,$CLJS.CG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.A0.j(a,b,d))].join("")});$CLJS.qF($CLJS.CG,j4);$CLJS.y0.m(null,$CLJS.pG,function(){return $CLJS.KE("Share of rows matching condition")});$CLJS.z0.m(null,$CLJS.pG,function(){return"share"});$CLJS.E0.m(null,$CLJS.pG,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.nI($CLJS.E0,j4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Tj,$CLJS.lD)});
$CLJS.qF($CLJS.pG,j4);$CLJS.y0.m(null,$CLJS.rG,function(){return $CLJS.KE("Count of rows matching condition")});$CLJS.E0.m(null,j4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.el.l;d=$CLJS.n(d)?$CLJS.cm($CLJS.Y_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cL,$CLJS.Tj],null)):null;var f=$CLJS.nI($CLJS.E0,$CLJS.ci);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.el,$CLJS.H([d,a]))});
$CLJS.iqa=function(){function a(d){return $CLJS.Y1($CLJS.Qx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.Y1($CLJS.Qx,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.oW.m(null,$CLJS.YF,function(a){return a});
$CLJS.F4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.dC(f),$CLJS.TK))f=$CLJS.qW(f);else return $CLJS.a2(d,e,$CLJS.YF,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.G4=function(){function a(d,e){return $CLJS.Fe($CLJS.YF.h($CLJS.FW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.H4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.YF.h($CLJS.FW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.Y_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.pB.h(l);return m.call($CLJS.R,$CLJS.X1(l,$CLJS.Ei,$CLJS.n(t)?t:$CLJS.zj),$CLJS.PK,$CLJS.jL,$CLJS.H([$CLJS.b1,$CLJS.zE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.y0.m(null,$CLJS.RG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.NG);return $CLJS.nE.h(a.o?a.o():a.call(null))});$CLJS.F0.m(null,$CLJS.RG,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.bG);b=$CLJS.J.g(d,$CLJS.NG);c=$CLJS.J.g(d,$CLJS.LG);d=$CLJS.J.g(d,$CLJS.KZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.w0,$CLJS.WA(a),$CLJS.H([$CLJS.u0,c]));return null!=d?$CLJS.R.j(a,$CLJS.g0,d):a});
$CLJS.jqa=function(){function a(d,e){var f=function(){var m=$CLJS.eA.h($CLJS.s1($CLJS.S_(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.FW(d,e),l=$CLJS.J0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Nk.j($CLJS.kf(function(m){m=$CLJS.PG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Vl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.LG),u=$CLJS.J.g(m,$CLJS.JG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.Gs))return $CLJS.R.j(m,$CLJS.CW,l);t=$CLJS.Fe($CLJS.X0(function(v){return $CLJS.u_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.CW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.Xt,$CLJS.RG)})),$CLJS.SG))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.kqa=function(){function a(d,e){return $CLJS.pW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG.h(d),$CLJS.N,$CLJS.rW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.LG.h(d)))return $CLJS.pW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bG.h(d),$CLJS.N],null));var e=$CLJS.bG.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.hi(e,new $CLJS.h(null,1,[Tpa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();